import { Router } from "@solidjs/router"
import { FileRoutes } from "@solidjs/start/router"
import { Suspense } from "solid-js"
import { withSentryRouterRouting } from "@sentry/solidstart/solidrouter"
import "./app.css"
import { MetaProvider } from "@solidjs/meta"
import { Toaster } from "./components/ui/toast"

const SentryRouter = withSentryRouterRouting(Router)

export default function App() {
  return (
    <MetaProvider>
      <SentryRouter
        root={(props) => (
          <>
            <Suspense>{props.children}</Suspense>
          </>
        )}
      >
        <FileRoutes />
      </SentryRouter>
      <Toaster />
    </MetaProvider>
  )
}
