// @refresh reload
import "solid-devtools"
import * as Sentry from "@sentry/solidstart"
import { solidRouterBrowserTracingIntegration } from "@sentry/solidstart/solidrouter"
import { mount, StartClient } from "@solidjs/start/client"

Sentry.init({
  dsn: "https://918464035d50ae1d9b6ba42f3882984f@o4505345700986880.ingest.us.sentry.io/4508030382833664",
  integrations: [
    solidRouterBrowserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/jianji.114120\.xyz\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

mount(() => <StartClient />, document.getElementById("app")!)
